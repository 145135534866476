

.carousel-container {
  text-align: center;
  margin: 20px;
}

.carousel-title {
  font-size: 48px;
  margin-bottom: 25px;
}

.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 80%; /* Width of carousel */
}

.carousel-item {

  text-align: center;
  margin: 10px;
}

.carousel-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.carousel-arrow {
  background-color: #fff;
  border-radius: 15px;

  cursor: pointer;
  font-size: 32px; /* 2rem to px */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  z-index: 10;
  border: 1px solid black;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

h4 {
  font-size: 19.2px; /* 1.2rem to px */
  margin-top: 10px;
}

p {
  font-size: 16px; /* 1rem to px */
}


@media (max-width: 1030px) {

  .carousel-title {
    font-size: 32px;
  }
 
}

@media (max-width: 500px) {
  .carousel-arrow {
  font-size: 30px; /* 2rem to px */
  padding: 5px;
  border: .5px solid black;
}

}



