


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 20px;
  background-color: black;
  color: white;
  height: 120px;
  margin-left: 5px;
  margin-right: 5px;
}

.logo {
  display: flex;
  position: relative;
  width: 300px; /* Change this value to adjust the width */
  height: auto; /* Keeps the aspect ratio */
  margin-top: 40px;
}
.book-chat {
  display: flex;
  align-items: center;
  background-color: rgb(236, 101, 74);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
  font-size: 16px; /* Adjust size as needed */
  transition: background-color 0.3s ease; /* Smooth transition */
  transition: color- 0.3s ease; /* Smooth transition */
}

.book-chat:hover {
  background-color: white; /* Darker shade on hover */
  color: black;
}

/* Mobile responsiveness */
@media (max-width: 639px) {

.logo {

  width: 180px; /* Change this value to adjust the width */
  
}

.book-chat {
  font-size: 13px; /* Adjust size as needed */
}



 
}
