.property-host-details {
  display: flex;
  align-items: center; /* Vertically align the text and image */
  gap: 16px; /* Add spacing between the image and the text */
  margin-top: 16px; /* Add spacing above the host section */
}

.property-host {
  font-size: 18px; /* Slightly larger for readability */
  color: #2d3748; /* Consistent with other text colors */
  font-weight: 600; /* Make the text bold */
}

.host {
  width: 190px; /* Adjust size to ensure it fits nicely */
  height: 190px; /* Make the image circular */
  border-radius: 50%; /* Circular image */
  
  
}




.property-container {
  background-color: #f9fafb;
  border-radius: 8px; /* 0.5rem */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px auto; /* Center container with some vertical margin */
  max-width: 1200px; /* Limit width for desktop */
  padding: 16px; /* Add some padding */
  margin-top: 80px;
}

.property-header {
  text-align: center;
  margin-bottom: 24px; /* 1.5rem */
}

.property-title {
  font-size: 48px; /* 1.75rem for desktop */
  font-weight: bold;
  color: #2d3748;
}

.prop-descrip-con {
  display: flex; /* Use flexbox to align left and right sections */
  flex-direction: row; /* Horizontal layout */
  justify-content: space-between; /* Ensure spacing between sections */
  align-items: flex-start; /* Align sections at the top */
  gap: 32px; /* Space between content and stats */
}

.property-content {
  flex: 1 1 50%; /* Occupy 35% of the available width */
  background: #fff; /* Ensure clear visual separation */
  border-radius: 8px; /* Match overall design */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for emphasis */
  padding: 16px; /* Add internal padding */
}

.property-image-wrapper {
  flex: 1 1 auto;
}

.clean-im {
  border-radius: 8px; /* 0.5rem */
  width: 100%; /* Adjust to full width of its container */
  object-fit: cover;
  height: 40vh;
}

.property-details {
  flex: 1 1 auto;
}

.property-name {
  font-size: 24px; /* Slightly larger for readability */
  font-weight: 600;
  color: #2d3748;
}

.property-location {
  font-size: 16px; /* Slightly larger for readability */
  color: #4a5568;
}

.property-attributes,
.property-host,
.property-story {
  margin-top: 8px; /* 0.5rem */
  color: #4a5568;
  font-size: 16px; /* Larger for desktop */
}

.property-description {
  margin-top: 16px; /* 1rem */
  font-weight: 500;
  font-size: 24px;
}

.stats-container {
  flex: 1 1 50%; /* Occupy 35% of the available width */
  background: #fff; /* Ensure clear visual separation */
  border-radius: 8px; /* Match overall design */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for emphasis */
  padding: 16px; /* Add internal padding */
}

.property-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for stats */
  gap: 16px; /* 1rem gap between stats */
  margin-top: 16px; /* Ensure some spacing above */
  text-align: center;
}

.property-stat {
  padding: 16px; /* Space inside each stat */
  background-color: rgb(236, 101, 74);
  border-radius: 8px; /* Match the overall card style */
  display: flex;
  flex-direction: column; /* Align text properly */
  align-items: center; /* Center-align the text */
}

.stat-value {
  display: flex;
  align-items: center;
  font-size: 24px; /* 1.5rem for emphasis */
  font-weight: bold;
  color: white;
}

.stat-label {
  font-size: 16px; /* 0.875rem */
  color: white;
  font-weight: bold;
  margin-top: 0px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .prop-descrip-con {
    flex-direction: column; /* Stack sections vertically */
    gap: 16px; /* Smaller gap for mobile */
  }

  .property-content,
  .stats-container {
    flex: 1 1 100%; /* Both sections take full width */
  }

  .clean-im {
    width: 100%; /* Make the image full-width on smaller screens */
  }
}

