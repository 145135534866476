.booking-banner {
  background-color: whitesmoke;
  color: white;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  width: 100%;
  display: flex; /* Enables flexbox */
  flex-direction: column; /* Stacks the heading and images vertically */
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
}

.booking-platforms {
  display: flex;
  justify-content: center;
  gap: 40px; /* Space between the images */
  margin-top: 20px;
}

.booking-platforms img {
  height: 40px; /* Default size for images */
  object-fit: contain;
}

.your-list {
    color: black;
}

/* Responsive styles for mobile only */
@media (max-width: 768px) {
  .booking-platforms {
    gap: 20px; /* Reduce gap between images */
    flex-wrap: wrap; /* Allow images to wrap on smaller screens */
  }

  .booking-platforms img {
    height: 25px; /* Slightly smaller images for tablets */
  }

  .your-list {
    font-size: 16px; /* Adjust heading font size for tablets */
  }
}

@media (max-width: 480px) {
  .booking-banner {
    padding: 30px 10px; /* Adjust padding for small screens */
  }

  .booking-platforms {
    gap: 10px; /* Further reduce gap for very small screens */
  }

  .booking-platforms img {
    height: 20px; /* Smallest size for images */
  }

  .your-list {
    font-size: 22px; /* Adjust font size further for very small screens */
  }
}





