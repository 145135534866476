.crossed-out {
  text-decoration: line-through;
  color: #bbb; /* Optional: Use a lighter color for the crossed-out text */
  margin-left: 10px; /* Adds space between 16% and 20% */
}



.price-card-container {
  max-width: 700px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  margin-top: 80px;

}

.price-card-promo {
  text-align: center;
  margin-bottom: 20px;
}

.promo-heading {
  font-size: 48px;
  color: #2c3e50; /* Darker blue for the heading */
  margin-bottom: 10px;
}

.promo-text {
  font-size: 1em;
  color: #555;
  margin-bottom: 10px;
}

.price-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: rgb(236, 101, 74);
  overflow: hidden;
}

.price-card-heading-box {
  background-color: #000;
  width: 100%; /* Full width of the parent container */
  padding: 15px 0;
}

.price-card-heading {
  text-align: center;
  color: #fff;
  font-size: 48px;
  margin: 0;
}

.price-card-list {
  list-style-type: none;
  padding: 20px;
  margin: 0;
  color: white;
  font-size: 18px;
}

.price-card-list li {
  margin: 10px 0;
  font-size: 1em;
}

/* Mobile responsiveness */
@media (max-width: 760px) {
  .price-card-container {
    max-width: 90%;
  }

  .promo-heading {
    font-size: 1.2em;
  }

  .promo-text {
    font-size: 0.9em;
  }

  .price-card-heading {
    font-size: 1.2em;
  }

  .price-card-list li {
    font-size: 0.9em;
    margin: 8px 0;
  }


  .price-card-promo {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 50px;
}


}

