.align-c {
    text-align: center;
}

.back-container {
    background-color: #f8f5f5;
    width: 100%;
    padding-bottom: 50px;
}

.property-management {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 100px;
  
}



.body-prop-m {
  
  margin-bottom: 100px;
}

.box-container {
  display: flex;
  flex-direction: column; /* Ensure boxes stay in a column layout */
  gap: 20px;
  width: 100%;
}

.box {
  position: relative;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;

  background-color: white;
  border-radius: 20px;
}

.box-number {
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: rgb(236, 101, 74);
  color: white;
  font-weight: bold;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box h4 {
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .property-management {
    margin-top: 60px;
    padding: 10px;
  }

  .heading-prop {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .body-text-prop,
  .box p {
    font-size: 16px;
  }

  .box {
    padding: 20px;
    max-width: 85%;
    
    margin: 0 auto;
  }

  .box-number {
    top: -8px;
    left: -8px;
    width: 20px;
    height: 20px;
    font-size: 0.8em;
  }

 
}


