
/* Footer container */
.footer-contact-link {
  color: rgb(247, 154, 154); /* Set the color to white or any color you prefer */
  text-decoration: none; /* Remove the underline */
}

/* Ensure that the hover effect is also styled appropriately */
.footer-contact-link:hover {
  color: white; /* Retain the same color on hover */
  text-decoration: none; /* Remove underline on hover */
}


/* Footer container */
.footer {
  position: relative;
  padding-top: 30px;

  color: white;
  width: 100%; /* Ensures footer takes up the full viewport width */
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay for readability */
  background-image: url('../assets/housetwo.png'); /* Set house-two image as background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; /* Prevents any overflow that might cause the gap */
}

/* Ensure body has no margin or padding to eliminate any gap */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Footer content positioning */
.footer-content {
  position: relative;
  z-index: 2; /* Ensure content is above the image */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 0 auto; /* Center the content */
  max-width: 1200px; /* Optional: to limit content width for readability */
}


/* Logo section */
.footer-logo img {
  max-width: 150px;
 margin-top: -60px;
  margin-bottom: -50px;
  margin-left: -20px;
  
}

/* Footer bottom text */
.footer-bottom {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  color: white;
}

/* Individual sections */
.footer-info,
.footer-contact,
.footer-nav,
.footer-services,
.footer-locations {
  margin-bottom: 20px;
}

.footer-nav ul,
.footer-services ul,
.footer-locations ul {
  list-style: none;
  padding: 0;
}

.footer-nav li,
.footer-services li,
.footer-locations li {
  margin-bottom: 10px;
}

.footer-nav a,
.footer-services a,
.footer-locations a {
  color: white;
  text-decoration: none;
}

.footer-nav a:hover,
.footer-services a:hover,
.footer-locations a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    margin: 0 auto;
    max-width: 550px;
  }

  /* Adjust spacing and font size on smaller screens */
  .footer-logo img {
    margin-bottom: 10px;
  }

  .footer-bottom {
    font-size: 12px;
  }
}

@media (max-width: 587px) {
  .footer-content {
    
    max-width: 400px;
  }

 
}

@media (max-width: 429px) {
  .footer-content {
    
    max-width: 310px;
  }

 
}
