


.book-chat-ser {
  display: flex;
  align-items: center;
  background-color: rgb(236, 101, 74);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
 
  font-size: 16px; /* Adjust size as needed */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.pro-pho-head {
  text-align: center;
}

.service-head-p {
  font-size: 22px;
  max-width: 80%;
}

.service-head, .pro-pho-head {
  font-size: 48px;
}

.service-head {
  font-weight: 500;
  text-align: left;
}

.photography-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  margin-top: 60px;
}

.text-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
 
}

.image-content {
  flex: 1;
  max-width: 50%;
  text-align: right; /* Keep right-aligned in larger views */
}

.photography-image {
  width: 90%; /* Set the width to 90% of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensures the image covers the area without stretching */
  border-radius: 10px;
  max-height: 400px; /* Optional: set a maximum height if needed */
}

.main-pho-section-wrap {
  margin-top: 100px;
}

/* Alternate the layout for every other photography section */
.photography-section:nth-child(even) {
  flex-direction: row-reverse; /* Text on the right, image on the left */
}

@media (max-width: 1030px) {

  .photography-image {
  width: 100%; /* Set the width to 90% of the container */
 
}
  .pro-pho-head {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  /* Stack the sections vertically */
  .photography-section {
    flex-direction: column !important; /* Force column direction for all sections */
    align-items: center;
    padding: 20px;
  }

  .text-content, .image-content {
    max-width: 90%; /* Full width for both text and image */
  }

  .image-content {
    order: -1; /* Make sure the image appears first */
    text-align: center; /* Center the image in mobile view */
  }

  .photography-image {
    max-width: 100%; /* Adjust image size for mobile */
    margin: 0 auto; /* Center the image horizontally */
  }

  .service-head {
    font-size: 26px;
  }

  .service-head-p {
    font-size: 18px;
    max-width: 100%;
  }

  .text-content {
    padding: 0px;
  }
}

@media (max-width: 480px) {
  /* Further adjustments for smaller screens */
  .service-head  {
    font-size: 22px;
  }

  .service-head-p {
    font-size: 16px;
  }

  .book-chat-ser {
    font-size: 16px;
    padding: 8px 16px;
  }

  /* Adjust image size for very small screens */
  .photography-image {
    max-width: 100%;
  }
}


