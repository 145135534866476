
/* Prevent horizontal overflow */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Fix horizontal overflow */
}





.close-button {
  background-color: rgb(236, 101, 74);
  border-radius: 5px;
  border: none;


}


.book-chat-top {
  background-color: rgb(236, 101, 74);
  color: white;
  border: none;
 padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
  font-size: 22px; /* Adjust size as needed */
  transition: background-color 0.3s ease; /* Smooth transition */
  transition: color- 0.3s ease; /* Smooth transition */
  font-weight: 600;
  
}



.main-body-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.home-top {
  position: relative; /* Allow positioning of headers on top of the house */
  width: 100%; /* Full width */
}

.house {
  width: 100%; /* Make house image full width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Prevent image from stretching */
}

.house-two {
  width: 100%; /* Make house image full width */
  height: 500px; /* Maintain aspect ratio */
  object-fit: cover; /* Prevent image from stretching */
}

/* Header section */
.header-main {
  position: absolute; /* Position on top of the house */
  top: 10px; /* Adjust top positioning as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Shift left to center */
  text-align: center;
  color: white;
  padding: 0 10px;
  width: 100%; /* Full width for header */
  z-index: 1; /* Lower z-index for header */
}

/* Text overlay (des-headers) */
.des-headers {
  position: absolute; /* Position on top of the house */
  top: 50%; /* Adjust as needed for desktop */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 0 10px;
  z-index: 2; /* Higher z-index to stay above the header and image */
}

.main-head {
  font-size: 58px; /* Adjust font size for main heading */
  margin: 0;
}

.sec-head {
  font-size: 22px; /* Adjust font size for secondary heading */
  margin: 10px 0 0;
  font-weight: 200;
}


.heading-prop {
    font-size: 48px;
}

.body-text-prop {
    font-size: 22px;
}

.property-management-container {
    text-align: center;
    max-width: 1200px;
    margin: 20px;
}

/* Mobile responsiveness */
@media (max-width: 1030px) {
  /* Increase image height without stretching */
  .house {
    height: 100vh; /* Set height to 60% of viewport without distorting */
    object-fit: cover; /* Ensures the image fills the area without stretching */
  }

  /* Adjust the text overlay positioning */
  .des-headers {
    top: 50%; /* Push the text overlay further down on mobile */
    width: 80%;
 
    
  }

  .main-head {
    font-size: 32px; /* Slightly smaller text for mobile */
  }

  .sec-head {
    font-size: 20px; /* Slightly smaller text for mobile */
  }

  .header-main {
    top: 20px; /* Adjust header positioning for mobile */
  }

  .heading-prop {
    font-size: 32px;
}

.body-text-prop {
    font-size: 20px;
}

.property-management-container {
    max-width: 800px;
    margin-left: 35px;
    margin-right: 35px;
}
}


@media (max-width: 400px) {

 .des-headers {

  top: 60%; /* Adjust as needed for desktop */

} 

}






