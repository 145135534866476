.call-button {
  display: flex;
  margin: 0 auto;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  background-color: rgb(236, 101, 74);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 40px;
  font-size: 16px; /* Adjust size as needed */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.next-container {
  width: 100vw;
  margin-left: calc((100vw - 100%) / -2); /* Centers the container */
  background-color: black;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  margin-top: -80px; /* Adjust this to control the overlap amount */
  position: relative;
  z-index: 1;
}

.color-next {
  color: white;
  margin-top: 150px;

  
  
}

.color-next-p {
  margin-top: -10px;
 
  font-size: 22px;
  max-width: 700px;
  margin: 0 auto;
  
}

.where-we-operate {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  margin-top: 60px;
  position: relative;
  z-index: 2; /* Ensures it appears above the black background */
}





.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 230px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.l {
  width: 100%;
  height: auto;
  display: block;
}

.image-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}



@media (max-width: 768px) {
  .image-container {
    max-width: 550px;
  }

  .heading {
    font-size: 20px;
  }

  .image-title {
    font-size: 14px;
  }

  .color-next-p {
   font-size: 16px;
    padding: 30px;
}




}

