.reviews-container {
   
  margin-top: 50px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.review {
  background-color: #f8f5f5;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.stars {
  color: gold;
  font-size: 18px;
  margin-bottom: 10px;
}

.star {
  margin-right: 2px;
}

.review-text {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.reviewer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reviewer-name {
  font-size: 18px;
  font-weight: bold;
}

.reviewer-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 1055px) {
  .reviews-container {
   
  max-width: 600px;
}



}

@media (max-width: 648px) {
  .reviews-container {
   
  max-width: 450px;
}



}

@media (max-width: 483px) {
  .reviews-container {
   
  max-width: 320px;
}



}


