





.form-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    background-color: black;
    align-items: center;
    padding-bottom: 40px;
    border-radius: 15px;

}


.form-contact {
  display: flex;
  flex-direction: column;

}

.input-contact {
  margin-bottom: 20px;
}

.input-button {
    margin-bottom: 30px;
    background-color: rgb(236, 101, 74);
    border: none;
    border-radius: 20px;
    color: whitesmoke;
    font-size: 22px;
    margin-top: 40px;
}

.input-button:hover {
  background-color:  whitesmoke;
  color: black;
}



.last_input {
    
    height: 40px;
    font-weight: 700;
}

input {
  width: 305px;
  height: 25px;
  
  
}

textarea {
  width: 305px;
}

.last_input {
    width: 310px;
}

label {
    color: whitesmoke;
    font-weight: 700;
}

.popform__heading {
    color: whitesmoke;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
}

.popform__offer {
  color: whitesmoke;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 40px;
}

.result-msg {
    color: rgb(236, 101, 74);
    margin-top: -15px;
}

@media only screen and (max-width: 405px) {
  .popform__heading {
    color: whitesmoke;
    text-align: center;
    font-size: 24px;
    font-weight: 700;

}


.form-container {
   
    width: 340px;
   
}




}